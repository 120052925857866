import React, { useState, useEffect, useMemo } from 'react';
import { ChevronDown } from 'lucide-react';

interface LeaderboardEntry {
    model_id: string;
    model_name: string;
    elo_score: number;
    win_count: number;
}

const BASE_URL = process.env.REACT_APP_API_URL;

const Leaderboard: React.FC = () => {
    const [leaderboard, setLeaderboard] = useState<LeaderboardEntry[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [categories, setCategories] = useState<string[]>([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<string>('');

    useEffect(() => {
        fetchCategories();
        fetchLeaderboard();
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await fetch(`${BASE_URL}/categories`);
            if (!response.ok) {
                throw new Error('Failed to fetch categories');
            }
            const data = await response.json();
            if (Array.isArray(data)) {
                setCategories(data);
            } else {
                console.error('Unexpected data format for categories:', data);
                setCategories([]);
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
            setCategories([]);
        }
    };

    const handleCategorySelect = async (category: string) => {
        setIsDropdownOpen(false);
        setSelectedCategory(category);
        fetchLeaderboard(category);
    };

    const fetchLeaderboard = async (category?: string) => {
        setIsLoading(true);
        try {
            const url = category
                ? `${BASE_URL}/leaderboard?category=${encodeURIComponent(category)}`
                : `${BASE_URL}/leaderboard`;
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data: LeaderboardEntry[] = await response.json();
            console.log(data);
            setLeaderboard(data);
        } catch (error) {
            console.error('Error fetching leaderboard:', error);
            setError(error instanceof Error ? error.message : 'An unknown error occurred');
        } finally {
            setIsLoading(false);
        }
    };

    const totalWins = useMemo(() => {
        return leaderboard.reduce((sum, entry) => sum + entry.win_count, 0);
    }, [leaderboard]);

    if (error) {
        return <div className="text-red-500 mt-20">Error: {error}</div>;
    }

    return (
        <div className="max-w-3xl mx-auto p-4 mt-8">
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-bold">Leaderboard</h1>
                <div className="relative">
                    <button 
                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                        className="px-3 py-1 text-sm bg-gray-200 text-gray-700 rounded-full hover:bg-gray-300 transition-colors flex items-center"
                    >
                        {selectedCategory || 'All Categories'}
                        <ChevronDown className="ml-1 h-4 w-4" />
                    </button>
                    {isDropdownOpen && Array.isArray(categories) && categories.length > 0 && (
                        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                            <button
                                onClick={() => handleCategorySelect('')}
                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            >
                                All Categories
                            </button>
                            {categories.map((category) => (
                                <button
                                    key={category}
                                    onClick={() => handleCategorySelect(category)}
                                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                >
                                    {category}
                                </button>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            <h2 className="text-lg mb-2">Total Rounds Played: {totalWins}</h2>

            {isLoading ? (
                <div className="flex justify-center items-center h-64">
                    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
                </div>
            ) : (
                <table className="min-w-full bg-white mt-4">
                    <thead>
                        <tr>
                            <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Rank
                            </th>
                            <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Model
                            </th>
                            <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                ELO Score
                            </th>
                            <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                                Wins
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {leaderboard.map((entry, index) => (
                            <tr key={index}>
                                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-300">
                                    {index + 1}
                                </td>
                                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-300">
                                    {entry.model_name}
                                </td>
                                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-300">
                                    {Math.round(entry.elo_score)}
                                </td>
                                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-300">
                                    {entry.win_count}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default Leaderboard;