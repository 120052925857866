import React from 'react';

const About: React.FC = () => {
  return (
    <div className="max-w-3xl mx-auto p-4 mt-8">
      <h1 className="text-2xl font-bold mb-4">Imgen Arena</h1>
      <p className="text-md mb-4">
        Imgen Arena is an arena-style system for evaluating image generation models. Since it is difficult to build objective benchmarks for image generation, Imgen Arena aims to provide a way to benchmark models against each other, based on user feedback.
      </p>

      <h2 className="text-xl font-semibold mb-2 mt-6">Supported Models</h2>
      <ul className="list-disc list-inside mb-4">
        <li>SDXL Lightning 4-step</li>
        <li>Stable Diffusion</li>
        <li>Stable Diffusion 3</li>
        <li>Flux Pro</li>
        <li>Flux Schnell</li>
        <li>Flux Dev</li>
        <li>Kandinsky 2.2</li>
        <li>DALL-E 3</li>
        <li>Midjourney</li>
      </ul>

      <h2 className="text-xl font-semibold mb-2 mt-6">Future Extensions</h2>
        <ul className="list-disc list-inside mb-4">
          <li>Adding new models, particularly Google Imagen3 (waiting for API access) and Llama3</li>
          <li>Create new categories of prompts, such as "realistic", "portrait", "abstract", specific styles, images with text, etc. and have separate leaderboards for each category.</li>
        </ul>

      <h2 className="text-xl font-semibold mb-2 mt-6">Contributing</h2>
      <p className="text-md mb-4">
        Check out the Github <a href="https://github.com/0xtuba/imgarena/" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">here.</a>
      </p>    

      <h2 className="text-xl font-semibold mb-2 mt-6">Sponsors</h2>
      <p className="text-md mb-4">
        Special thanks to <a href="https://www.fal.ai/" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">fal.ai</a> for sponsoring credits for this project.
      </p>
    </div>
  );
};

export default About;