import React, { useState, useEffect } from 'react';
import { ArrowRight, ChevronDown } from 'lucide-react';
import LazyImage from './LazyImage';

interface ImageData {
    image_id: string;
    image_url: string;
}

interface PromptResponse {
    prompt: string;
    prompt_category: string;
    prompt_id: string;
    images: ImageData[];
}

interface FavoriteSelection {
    prompt_id: string;
    image1_id: string;
    image2_id: string;
    image3_id: string;
    image4_id: string;
    winner_id: string;
}

const BASE_URL = process.env.REACT_APP_API_URL;

const ImageSelector: React.FC = () => {
    const [prompt, setPrompt] = useState<PromptResponse>({
        prompt: '',
        prompt_category: '',
        prompt_id: '',
        images: [],
    });
    const [error, setError] = useState<string | null>(null);
    const [categories, setCategories] = useState<string[]>([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<string>('');

    const [previousWinner, setPreviousWinner] = useState<string | null>(null);

    useEffect(() => {
        fetchCategories();
        fetchImages();
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await fetch(`${BASE_URL}/categories`);
            if (!response.ok) {
                throw new Error('Failed to fetch categories');
            }
            const data = await response.json();
            if (Array.isArray(data)) {
                setCategories(data);
            } else {
                console.error('Unexpected data format for categories:', data);
                setCategories([]);
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
            setError(error instanceof Error ? error.message : 'An unknown error occurred');
            setCategories([]);
        }
    };

    const handleCategorySelect = async (category: string) => {
        setIsDropdownOpen(false);
        setSelectedCategory(category);
        setPreviousWinner(null); // Reset the previous winner when changing categories
        try {
            const response = await fetch(`${BASE_URL}/prompt?category=${encodeURIComponent(category)}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data: PromptResponse = await response.json();
            setPrompt(data);
        } catch (error) {
            console.error('Error fetching images for category:', error);
            setError(error instanceof Error ? error.message : 'An unknown error occurred');
        }
    };

    const fetchImages = async () => {
        try {
            const url = selectedCategory
                ? `${BASE_URL}/prompt?category=${encodeURIComponent(selectedCategory)}`
                : `${BASE_URL}/prompt`;
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data: PromptResponse = await response.json();
            setPrompt(data);
        } catch (error) {
            console.error('Error fetching images:', error);
            setError(error instanceof Error ? error.message : 'An unknown error occurred');
        }
    };

    const handleImageClick = async (winnerId: string) => {
        if (prompt.images.length !== 4) {
            setError('Expected 4 images for comparison');
            return;
        }

        try {
            const response = await fetch(`${BASE_URL}/select-favorite`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    prompt_id: prompt.prompt_id,
                    image1_id: prompt.images[0].image_id,
                    image2_id: prompt.images[1].image_id,
                    image3_id: prompt.images[2].image_id,
                    image4_id: prompt.images[3].image_id,
                    winner_id: winnerId,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to record selection');
            }

            const result = await response.json();
            console.log('Result:', result);
            setPreviousWinner(result.winner_model);
            fetchImages(); // Fetch new images immediately
        } catch (error) {
            console.error('Error selecting favorite:', error);
            setError(error instanceof Error ? error.message : 'An unknown error occurred');
        }
    };

    if (error) {
        return <div className="text-red-500">Error: {error}</div>;
    }

    return (
        <div className="max-w-3xl mx-auto p-4 mt-8"> 
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-bold">Choose The Best Image Based On The Prompt</h1>
                <div className="relative">
                    <button 
                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                        className="px-3 py-1 text-sm bg-gray-200 text-gray-700 rounded-full hover:bg-gray-300 transition-colors flex items-center"
                    >
                        {selectedCategory || 'Select Category'}
                        <ChevronDown className="ml-1 h-4 w-4" />
                    </button>
                    {isDropdownOpen && Array.isArray(categories) && categories.length > 0 && (
                        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                            {categories.map((category) => (
                                <button
                                    key={category}
                                    onClick={() => handleCategorySelect(category)}
                                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                >
                                    {category}
                                </button>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            {previousWinner && (
                <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4">
                    <p>Your previous round selection:</p>
                    <p className="font-bold text-lg">{previousWinner}</p>
                </div>
            )}
            <h2 className="text-lg mb-4">
                {prompt.prompt}
            </h2>
            <div className="grid grid-cols-2 gap-6">
                {prompt.images.map((image) => (
                    <button
                        key={image.image_id}
                        onClick={() => handleImageClick(image.image_id)}
                        className={`aspect-square bg-gray-100 rounded-md overflow-hidden shadow-md transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-500`}
                    >
                        <LazyImage 
                            src={image.image_url} 
                            alt={`${image.image_id}`} 
                            className="w-full h-full object-cover"
                        />
                    </button>
                ))}
            </div>
        </div>
    );
};

export default ImageSelector;