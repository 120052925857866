import React from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import ImageSelector from './ImageSelector';
import Leaderboard from './Leaderboard';
import About from './About';

const App: React.FC = () => {
    return (
        <Router>
            <div className="flex flex-col min-h-screen">
                <nav className="bg-gray-800 p-4 sticky top-0 z-10">
                    <ul className="flex space-x-8">
                        <li>
                            <Link to="/" className="text-white hover:text-gray-300">Imgen Arena</Link>
                        </li>
                        <li>
                            <Link to="/leaderboard" className="text-white hover:text-gray-300">Leaderboard</Link>
                        </li>
                        <li>
                            <Link to="/about" className="text-white hover:text-gray-300">About</Link>
                        </li>
                    </ul>
                </nav>

                <main className="flex-grow">
                    <Routes>
                        <Route path="/" element={<ImageSelector />} />
                        <Route path="/leaderboard" element={<Leaderboard />} />
                        <Route path="/about" element={<About />} />
                    </Routes>
                </main>
            </div>
        </Router>
    );
};

export default App;